@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

body {
    font-family: 'Source Code Pro', monospace;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
