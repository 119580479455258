.button {
    color: white;
    background-color: transparent;
    padding: 1em 2em;
    border: 2px solid white;
    border-radius: 5px;
    font-family: 'Source Code Pro', monospace;
    cursor: pointer;
    margin-bottom: 1em;
    margin-right: 1em;
    width: 100%;
}

.button:last-of-type {
    margin-right: 0;
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button:active {
    background-color: white;
    color: #323232;
}

.active {
    background-color: white;
    color: black;
}