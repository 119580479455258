.container {
    padding: 0 0 4em 0;
    display: flex;
    justify-content: center;
}

.buttons {
    min-width: 15em;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
}

@media (max-width: 600px) {
    .buttons { width: 100% }
}

.sliders {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sliders {
    flex-basis: 20%;
}
.qubit {
    flex-basis: 60%;
    position: relative;
    display: flex;
    justify-content: center;
}

.qubitFW {
    flex-basis: 100%;
}

@media (max-width: 1100px) {
    .sliders {
        display: none;
    }
}