.toggle {
    width: 5em;
    position: absolute;
    left: -4.4em;
    transform: rotate(90deg);
    text-align: center;
    z-index: 100;
    padding: 0.5em;
    border: 2px solid white;
    background-color: #323232;
    cursor: pointer;
    border-radius: 0 0 5px 5px;
    opacity: 0.5;
}

.toggle0 {
    top: 3em;
}

.toggle1 {
    top: 9.1em;   
}

.toggleActive {
    border-top: 3px solid #323232;
    z-index: 10;
    opacity: 1;
}

.sidePanel {
    position: relative;
    top: 0;
    bottom: 0;
    background-color: #323232;
    /* border-left: 2px solid white; */
    min-height: 100vh;
}

@media (max-width: 786px) {
    .sidePanel {
        display: none;
    }
}

.sidePanel__content {
    background-color: #323232;
    position: absolute;
    top: 0;
    min-width: 20em;
    padding: 2em;
    min-height: calc(100% - 4em);
    border-left: 2px solid white;
}

.contentActive {
    z-index: 10;
    
}

.sidePanelOpen {
    position: absolute;
    right: 0;
    width: 24em;
    transition: 0.5s all ease-out;
}

.sidePanelClosed {
    width: 0;
}

.sidePanelMobile {
    @media (max-width: 1200px) {
        position: absolute;
        right: 0;
        max-width: calc(100vw - 34px);
    }
}

.buttons {
    width: 100%;
    margin-bottom: 0.75em;
    display: flex;
}

.measureContainer {
    position: relative;
}

.checkboxLabel {
    font-size: 0.75em;
    position: absolute;
    top: -0.6em;
    right: 0;
}

.checkbox {
    -webkit-appearance:none;
    -webkit-transition: .10s;
    height:1.25em;
    width:1.25em;
    cursor:pointer;
    position:relative;
    border-radius:5px;
    background-color:transparent;
    border: 2px solid white;
    position: relative;
    top: 0.5em;
}
.checkbox:checked {
    background-color:white;
}
.checkbox:before, .checkbox:checked:before {
    position:absolute;
    top:-0.2em;
    left:0;
    width:100%;
    height:100%;
    line-height:1.5em;
    text-align:center;
    color: #323232;
}
.checkbox:checked:before {
    content: 'X';
}