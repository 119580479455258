.ul {
    position: absolute;
    right: -40%;
    top: 0;
    margin-top: 0;
    list-style: none;
    padding-left: none;
    color: #03A062;
    height: 100%;
    overflow: hidden;
}

.item {
    opacity: 0.4;
}

@media (max-width: 786px) {
    .ul { right: 0 }
}

.vector {
    display: inline-block;
    padding-right: 0.5em;
    min-width: 4em;
    text-align: right;
    user-select: none;
}

.vector:last-of-type {
    padding-right: 0;
}

.label {
    color: white;
    text-align: center;
    display: inline-block;
    padding-right: 0.5em;
    min-width: 4em;
    user-select: none;
}